import React, { useState, useEffect } from 'react';

function Modal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    const today = new Date().toDateString();

    if (!lastVisit || new Date(lastVisit).toDateString() !== today) {
      // If last visit doesn't exist or if it's not today
      const showModalAfterDelay = setTimeout(() => {
        setShowModal(true);
        localStorage.setItem('lastVisit', new Date());
      }, 5000); // 5 seconds in milliseconds

      return () => clearTimeout(showModalAfterDelay); // Clear timeout if component unmounts
    }
  }, []); // Only run once when component mounts

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <dialog open className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Hello!</h3>
            <p className="py-4">Press ESC key or click the button below to close</p>
            <div className="modal-action">
              <button className="btn" onClick={closeModal}>Close</button>
            </div>
          </div>
        </dialog>
      )}
    </div>
  );
}

export default Modal;
