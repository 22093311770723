import React from 'react';

import { useState } from 'react';
import { BeakerIcon, CalendarIcon, MapIcon, UserIcon } from '@heroicons/react/24/outline'
import { Helmet } from 'react-helmet';
function Party(props) {

    var resultsDiv = document.getElementById('resultsDiv');
    var clearDiv = document.getElementById('clearDiv');
    
        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [email, setEmail] = useState('');
        const [phone, setPhone] = useState('');
    
    
    function validateEmail(email) {
        const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return regex.test(email);
    }
    
    
    
    
        const handleSubmit = (event) => {
            event.preventDefault(); // Prevents the default form submission behavior
            // Here, you can handle the submission, e.g., send data to an API
            
            if(validateEmail(email)){
                console.log("fetching");
    
                fetch('/api/guestList', {
                    method: 'POST',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify(
                        {
                            "firstName": firstName,
                            "lastName": lastName,
                            "email":email,
                            "phone":phone
                        }
                    )})
                .then(response => response.json()) // parse JSON from response
                .then(data => console.log(data.text))
                .catch(error => console.error('Error:'+ error));
            
                clearDiv.innerHTML = "";
            
                resultsDiv.insertAdjacentHTML(
                    'beforeend', "<h1 className=\"text-lg text-[#6c89a5] mt-8 \">"+"Thank you for joining the guest list to our sneak peak event. <br/><br/> Your ticket for admittance will be sent to your email.<br/><br/> We look forward to showing you all the work we put into making a facility that feels like home for your furry family member!</h1>");}
                    else {
                        console.log("invalid entry")
                        resultsDiv.insertAdjacentHTML(
                            'beforeend', "<h1 className=\"text-lg text-red-600 mt-8 \">"+"Please Correct your Email Formatting!"+"</h1>");
                    }
            
    
            console.log(firstName, lastName, email);
        };

    return (
        <div>
             <Helmet>
        <title>MyK9 Sneak Peek Invite!</title>
        <meta name="description" content="Sign up here for a ticket to our sneak peek event. Come see the luxury space we have created for your furry family member!" />
        <meta property="og:title" content="MyK9 Sneak Peek RSVP!" />
        <meta property="og:description" content="Sign up here for a ticket to our sneak peek event. Come see the luxury space we have created for your furry family member!" />
        <meta property="og:image" content="https://myk9.dog/thumbnail.webp" />
        <meta property="og:url" content="https://myk9.dog/party" />
        {/* Additional tags as needed */}
      </Helmet>
        <div className="flex items-center flex-col bg-[#576DCC] pt-10">
        
        <img className="max-w-[100px]" src='/logo192.webp' alt="logo"/>
        
        <div className="max-w-2xl p-8 bg-white border-2 border-[#57a8cc] rounded-lg drop-shadow m-4">
            <div className="font-bold text-xl mb-2 text-[#314558]">RSVP Here!</div>
            <div id="clearDiv">
                <label className="text-lg text-[#6c89a5] self-center mt-4">Please sign up below to be a part of our sneak peek event</label>
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex flex-row gap-x-4 flex-wrap justify-items-start w-full">
                        <div className="flex no-wrap grow">
                            <label className="text-lg text-[#314558] shrink-0 self-center mt-6 mr-4">First Name:</label>
                            <input className="w-full grow border border-slate-600 p-1 mt-6" id="firstName" placeholder="John"
                                value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="flex no-wrap grow">
                            <label className="text-lg text-[#314558] shrink-0 self-center mt-6 mr-4">Last Name:</label>
                            <input className="w-full border border-slate-600 p-1 mt-6" id="lastName" placeholder="Smith"
                                value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                    </div>
                    <div className="flex no-wrap grow">
                        <label className="text-[#314558] text-lg self-center mt-6 mr-4">Email:</label>
                        <input className="w-full border border-slate-600 p-1 mt-6" id="email" placeholder="yourname@email.com"
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="flex no-wrap grow">
                        <label className="text-[#314558] text-lg self-center mt-6 mr-4">Phone:</label>
                        <input className="w-full border border-slate-600 p-1 mt-6" id="phone" placeholder="888-888-8888"
                            value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="flex flex-row space-x-6 flex-wrap justify-center items-center">
                        <button type="submit" className="w-full text-white bg-[#57a8cc] border-slate-600 mt-10 mb-2 p-2 font-bold hover:bg-white hover:text-slate-600">Submit</button>
                    </div>
                </form>
            </div>
            <div id="resultsDiv">
                {/* Results or confirmation messages can be shown here */}
            </div>
            
        </div>

        
    </div>
            <div className="flex mt-[-144px] pt-[144px] flex-col items-center bg-[#F5F5F5]">
                <h1 className="pt-5 font-bold text-3xl text-[#314558] mb-2 ">Sneak Peek Details</h1>
                    
                    <div className="max-w-2xl m-6 mt-2 pt-0">

                        <div className=" flex flex-col mb-2">
                            <div className="flex flex-row items-center">
                            <CalendarIcon className="h-14 w-14 min-w-[45px] text-[#314558] m-4" />
                                <div className="ml-2">
                                    <h1 className="pt-2 font-bold text-xl text-[#314558]">Date & Time</h1>
                                    <p className="text-[#6c89a5]">Sneak Peek starts at 4:00pm on March 15th and ends at 8:00pm</p>
                                </div>
                            </div>    
                        </div>

                        <div className=" flex flex-col mb-2">
                            <div className="flex flex-row items-center">
                            <MapIcon className="h-14 w-14 text-[#314558] min-w-[45px] m-4" />
                                <div className="ml-2">
                                    <h1 className="pt-2 font-bold text-xl text-[#314558]">Address</h1>
                                    <a href="https://www.google.com/maps/place/MyK9/@41.6880038,-72.1867843,15z/data=!3m1!4b1!4m6!3m5!1s0x89e6651c0ee26e97:0x1966e51970709d6a!8m2!3d41.6879882!4d-72.176506!16s%2Fg%2F11vc74y21c?entry=ttu" target="_blank" rel="noopener noreferrer" className="text-[#6c89a5]">701 S Windham Rd, South Windham, CT 06266</a>

                                </div>
                            </div>    
                        </div>

                        <div className=" flex flex-col mb-2">
                            <div className="flex flex-row items-center">
                            <UserIcon className="h-14 w-14 min-w-[45px] text-[#314558] m-4" />
                                <div className="ml-2">
                                    <h1 className="pt-2 font-bold text-xl text-[#314558]">Dog Parents Only</h1>
                                    <p className="text-[#6c89a5]">We can't wait to meet your doggies, but this event is for dog parents only</p>

                                </div>
                            </div>     
                        </div>
                    </div>
            </div>
            

       </div>
    );
}

export default Party;