import React, { useState, useEffect } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(2);

  

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide ===  29 ? 1 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 1 ? 29 : prevSlide - 1));
  };

  useEffect(() => {
    const timer = setTimeout(nextSlide, 4000);

    // Clear the timer when the component unmounts or when currentSlide changes
    return () => clearTimeout(timer);
  }, [currentSlide, nextSlide]);
  return (
    <div className="w-full flex flex-row gap-x-2 justify-center">
      <button className="z-10 -mr-10 md:mr-0 md:block" onClick={prevSlide}><ArrowLeftIcon className="h-5 w-5" /></button>
      <div className="">
        <img className="h-[400px] object-contain"src={`/CarouselImages/${currentSlide}.webp`} alt={`Slide ${currentSlide + 1}`} />
      </div>
      <button className="z-10 -ml-10 md:ml-0 md:block md:block" onClick={nextSlide}><ArrowRightIcon className="h-5 w-5" /></button>
      
    </div>
  );
};

export default Carousel;