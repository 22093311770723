import React from "react"





function HomeFooter() {

    return (
   <div className="pb-6 md:pb-0 bg-cover bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url('/image/texture.webp')` }}>
  <h1 className="text-white md:text-5xl text-xl text-center max-w-[1000px] w-full font-black mb-6 mt-16 uppercase">A family run & owned business you can trust</h1>
  
  <div className="hidden md:flex flex-row  flex-wrap justify-between items-center max-w-[900px] w-full mb-20 mt-0">
    <div className="flex flex-col justify-center items-center">
      <img src="/image/cleanicon.webp" className="m-4 w-[100px]" />
      <h2 className="text-[#57a8cc] md:text-4xl font-black">CLEAN</h2>
      <h2 className="text-white font-black">Facility</h2>
    </div>
    <div className="flex flex-col justify-center items-center">
    <img src="/image/dogicon.webp" className="m-4 w-[100px]"/>
      <h2 className="text-[#57a8cc] md:text-4xl font-black">100%</h2>
      <h2 className="text-white font-black">Happy Dogs</h2>
    </div>
    <div className="flex flex-col justify-center items-center">
    <img src="/image/friendicon.webp" className="m-4 w-[100px]"/>
      <h2 className="text-[#57a8cc] md:text-4xl font-black">LIFETIME</h2>
      <h2 className="text-white font-black">Friendships</h2>
    </div>
  </div>
</div>
    )
}

export default HomeFooter



