function FiveStars() {
    return (
        <div className="flex flex-row gap-1">
            <img className="w-[23px] h-[25px]"src='./star.png'></img>
            <img className="w-[23px] h-[25px]"src='./star.png'></img>
            <img className="w-[23px] h-[25px]"src='./star.png'></img>
            <img className="w-[23px] h-[25px]"src='./star.png'></img>
            <img className="w-[23px] h-[25px]"src='./star.png'></img>
      </div>
    )
}

export default FiveStars