import { Route, Routes } from "react-router-dom"
import NavBar from './components/nav';
import { Contact } from "./components/pages/Contact"
import { Services } from "./components/pages/Services"
import { Home } from "./components/pages/Home"
import './App.css';
import Party from "./components/pages/Party";
import SignUp from "./components/pages/SignUp";
import Success from "./components/pages/Success";

function App() {
  return (
    <div className="App">
      <NavBar />
   
      <Routes>
        <Route path="/" element={<Home />}/>
        {/* <Route path="/about" element={<About />}/> */}
        <Route path="/contact" element={<Contact />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/party" element={<Party />}/>
        <Route path="/signup" element={<SignUp />}/>
        <Route path="/success" element={<Success />}/>
      </Routes>
      <footer className="footer bg-[#57a8cc] text-white p-10">
        <div className="max-w-7xl mx-auto w-full flex flex-wrap justify-between items-center">
          <div className="flex items-center">
            <img className='max-h-10 mr-2' src="./logo192.png" alt="MyK9 Logo" />
            <p id="title" className="mb-0 text-white text-2xl font-bold">MyK9</p>
          </div>
          <nav className="flex flex-col gap-2">
            <h6 className="footer-title">Services</h6>
            <a href="https://myk9.gingrapp.com/front_end/lead?form_name=cancel_freeze_request" target="_blank" rel="noopener noreferrer" className="link link-hover">Cancel / Freeze Form</a>
            <a href="https://myk9.gingrapp.com/front_end/lead?form_name=credit_increase_decrease" target="_blank" rel="noopener noreferrer" className="link link-hover">Change Frequency Request</a>
          </nav>
          <nav>
            <h6 className="footer-title">Social</h6>
            <div className="flex gap-6">
              <a href="https://www.facebook.com/myk9southwindham" target='_blank' rel="noopener noreferrer">
                <img className="max-h-6 cursor-pointer hover:scale-110" src="/image/facebook-icon.webp" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/myk9.ct/" target='_blank' rel="noopener noreferrer">
                <img className="max-h-6 cursor-pointer hover:scale-110" src="/image/instagram-icon.webp" alt="Instagram" />
              </a>
            </div>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;
