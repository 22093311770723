import React from 'react';

export const Services = () => {
    return (
        <div className="bg-gray-100">
      <div className="container mx-auto py-12">
        <h2 className="font-Lobster text-6xl  font-bold text-[#57a8cc]  text-center mt-8 mb-10">
          Treat your furry family member!  
        </h2>
        <div className="grid grid-cols-1 gap-8">
          {/* Doggy Daycare */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-1/2 order-2 md:order-1 p-6">
              <h3 className="text-3xl font-semibold text-gray-800 mb-4">
                Doggy Daycare
              </h3>
              <p className="text-gray-600 mb-4">
              Our small play groups focus on helping your dog properly socialize and learning to play appropriately. MyK9’s goal is to help your dog gain all the benefits of group play, without the negative side effects. 
              </p>
              <p className="text-gray-600">
              Our multi yard play area is designed to effectively create safe play groups. And give everyone the space they need to run around!
Our indoor play areas are equipped with 8mm rubber flooring to give your dog the traction they need to play safely inside.
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-2 p-6">
              <img src="/image/daycaredog1.png" alt="Doggy Daycare" className="rounded-lg shadow-lg max-h-[400px]" />
            </div>
          </div>
          {/* Boarding */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-1/2 order-2 md:order-2 p-6">
              <h3 className="text-3xl font-semibold text-gray-800 mb-4">
                Boarding
              </h3>
              <p className="text-gray-600 mb-4">
              Skip out on the jailhouse feel of the classic boarding kennel with our custom designed, stainless steel and tempered glass suites! With only 10 kennels in our entire facility, our focus is on minimizing stress during your dog’s stay.
              </p>
              <p className="text-gray-600 mb-4">
                By utilizing soundproof insulation and weatherproof doors, we are able to keep the noise transfer throughout the facility to a minimum. This means your dog will be able to settle easier without extra stimuli!
              </p>
              <p className='text-gray-600'>
              After enjoying their day of play and working on basic manners training, your dog will enjoy TV access while they lounge on their elevated K9Ballistics bed.
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-1 p-6">
              <img src="/image/GlassKennelDoors.webp" alt="Boarding" className="rounded-lg shadow-lg max-h-[400px]" />
            </div>
          </div>
          {/* Grooming */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-1/2 order-2 md:order-1 p-6">
              <h3 className="text-3xl font-semibold text-gray-800 mb-4">
                Grooming
              </h3>
              <p className="text-gray-600 mb-4">
                Our Fear Free Certified Groomer is trained to prioritize the emotional and physical well-being of your furry family member, ensuring grooming sessions are stress-free and positive experiences. This approach reduces anxiety for both the animal and their parent, leading to safer, more enjoyable grooming visits that enhance your dog's overall health and happiness.
              </p>
              <p className="text-gray-600">
                Add enrichment toys or a spa day to their boarding stay to enhance their stay even more!
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-2 p-6">
              <img src="/image/grooming.webp" alt="Grooming" className="rounded-lg shadow-lg max-h-[400px]" />
            </div>
          </div>
          {/* Training */}
          <div className="bg-white rounded-lg shadow-lg flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-1/2 order-2 md:order-2 p-6">
              <h3 className="text-3xl font-semibold text-gray-800 mb-4">
                Training
              </h3>
              <p className="text-gray-600 mb-4">
                Our CCPDT certified trainer uses science based training, customized for your puppy. With a goal of teaching you the skills to help streamline communication with your dog for lifelong results.</p>
              <p className="text-gray-600">
                Whether you're looking for basic obedience, reactivity rehab, or off leash reliability...we've got you covered!
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-1 p-6">
              <img src="/image/training.webp" alt="Training" className="rounded-lg shadow-lg max-h-[400px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

