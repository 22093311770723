import FiveStars from "./FiveStars.js";

const handleClick = () => {
  window.open('https://myk9.gingrapp.com/front_end/lead?form_name=lead_form', 'blank', 'noopener,noreferrer')
}


function HeroBanner() {
    return (
        <section className=" relative flex flex-col items-center justify-center text-white py-0">
        <div className="backdrop-brightness-90 video-docker flex items-center justify-center absolute w-full h-full overflow-hidden">
          <video className="hidden md:block absolute min-w-full min-h-full object-cover" autoPlay="{true}" loop muted playsInline src="herodog.mp4" type="video/mp4" ></video>
        </div> 
        <div className="bg-[url('../public/image/daycaredog.webp')] flex justify-center w-full">
        <div className="flex flex-row justify-center sm:justify-between w-[1330px]">

        <div className="hidden sm:inline drop-shadow-lg mt-16 mb-24 mx-4 lg:mt-18 lg:mb-18">
          <div className=" mt-6 ">
          <p className="text-8xl text-[#57a8cc] font-Lobster">Play all day</p>
          <p className="text-uppercase text-6xl font-black ml-6">wag all the way</p>
          </div>
        </div>
      
        <div className="bg-stone-50 text-[#181028] rounded p-3 drop-shadow-lg mt-12 mb-12 mx-4 sm:mx-0  lg:!mb-16">
          <h2 className="text-slate-600 sm:text-4xl text-xl sm:pr-20 font-bold">SOUTH WINDHAM</h2>
          
          <div className="flex flex-wrap gap-2">
            <FiveStars />
            <p className="text-slate-600 whitespace-nowrap mb-0">5 Star Rating -</p>
            <a className="">14 Reviews</a>
          </div>
          <p className="text-slate-600 mt-2 mb-0">701 Windham Rd, South Windham, CT 06266</p>
          <a className="" target="_blank" href="https://maps.app.goo.gl/Hsk56vt1Kbup2wq2A">Get Directions</a>
          <br/>
          <p className="text-slate-600 mt-2 mb-0">Phone: 860-400-2064</p>
          <div className="flex justify-center items-center">
          <button onClick={handleClick} className="bg-slate-400 mt-4 p-2 text-white font-medium" type="button"> Request Appointment </button>
          </div>
          </div>
          </div>
          </div>
         </section>
    )
}

export default HeroBanner