import React from 'react';
import { useState } from 'react';

export const Contact = () => {
  const clearDiv = document.getElementById('clearDiv');
  const resultsDiv = document.getElementById('resultsDiv');
  
  
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  function validateEmail(email) {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
}

const handleClick =(event)=>{
  event.preventDefault()
  
  if(validateEmail(email)){
  fetch('/api/contactEmail', {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify(
        {
            "name": name,
            "email":email,
            "message":message
        }
    )})
.then(response => response.json()) // parse JSON from response
.then(data => console.log(data.text))
.catch(error => console.error('Error:'+ error));

clearDiv.innerHTML = "";

clearDiv.innerHTML =
    "<h1 className=\"mx-auto w-full text-center\">Thank you, Email Sent!</h1>";
} else {
        console.log("invalid entry")
        resultsDiv.insertAdjacentHTML(
            'beforeend', "<h1 className=\"text-lg text-red-600 mt-8 \">"+"Please Correct your Email Formatting!"+"</h1>");
  
};}

    return (
      
    
      <div className="flex flex-col min-h-screen items-center bg-gray-100 px-4 py-8">
      <div className="hidden sm:block h-[100px]"></div>
      <div className="max-w-[1330px] w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:flex-1 p-6 md:p-8">
            <h2 className="sm:text-4xl text-2xl font-bold text-gray-800 mb-4">
              Contact Us
            </h2>
            <p className="text-gray-600 sm:text-xl mb-4">
              We'd love to hear from you! Whether you have questions about our services or just want to say hello, feel free to reach out using the form below or give us a call.
            </p>
            <p className="sm:text-xl text-gray-600 font-bold">
              Phone: 860-400-2064<br />
              Email: info@myk9.dog
            </p>
            <iframe className="mt-4 w-full h-64" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2979.507064342185!2d-72.17908092277267!3d41.687988171263235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e6651c0ee26e97%3A0x1966e51970709d6a!2sMyK9!5e0!3m2!1sen!2sus!4v1710380684124!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div id='clearDiv' className="flex-1 w-full p-6 md:p-8">
            <form className="flex flex-col h-full justify-between">
              <div>
                <div className="mb-4">
                  <label htmlFor="name" className="block sm:text-2xl text-gray-700 font-semibold mb-2">
                    Name
                  </label>
                  <input onChange={(x)=>{setName(x.target.value)}} type="text" id="name" name="name" placeholder="Your name" className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block sm:text-2xl text-gray-700 font-semibold mb-2">
                    Email Address
                  </label>
                  <input onChange={(x)=>{setEmail(x.target.value)}} type="email" id="email" name="email" placeholder="Your email" className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block sm:text-2xl text-gray-700 font-semibold mb-2">
                    Message
                  </label>
                  <textarea onChange={(x)=>{setMessage(x.target.value)}} id="message" name="message" rows="4" placeholder="Your message" className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"></textarea>
                </div>
              </div>
              <button onClick={handleClick} type="submit" className="bg-[#57a8cc] text-white font-semibold px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                Send Message
              </button>
              <div id="resultsDiv"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
    


      
    );
};
