import React from "react"




function LeftTextRightPhoto() {
    return (
       <div className="flex justify-center align-center bg-stone-50 md:pt-16 md:pb-16 pt-8 pb-12">
        <div className="flex flex-col md:flex-row max-w-[1330px]">
        <div className="md:w-1/2 pt-4 md:px-20 px-4">
          <h2 className="md:text-4xl text-2xl text-[#57a8cc] font-Lobster">Dogs gift us loyalty and happiness.</h2>
          <h2 className="text-2xl text-uppercase font-black text-slate-600 md:text-4xl">It’s time to reward their devotion.</h2>
          <p className="text-base mt-3 md:leading-9">
          We've established a caring environment tailored to the desires and necessities of dogs. Our facility provides all the essentials your beloved pet needs, including top-tier dog daycare, comprehensive boarding options for overnight stays, weekends, and holidays, as well as luxurious spa treatments. Our dedicated MyK9 staff members are expertly trained to offer your furry friend the same love and attention they receive at home. Rest assured, your dog will return home bursting with happiness and probably exhausted from the day's adventures. 
          </p>
        </div>
        <div className="md:w-1/2 p-4 md:p-0 flex justify-center items-center">
          <img src="/CarouselImages/1.webp" alt="Descriptive Alt Text" className="object-cover h-full max-h-[400px]" />
        </div>
      </div>
     </div>
    )
}

export default LeftTextRightPhoto