import React from 'react';
import HeroBanner from '../HeroBanner';
import ImageNav from '../ImageNav';
import LeftTextRightPhoto from '../LeftTextRightPhoto';
import HomeFooter from '../HomeFooter';
import CarouselSection from '../CarouselSection';
import Modal from '../Modal';


export const Home = () => {
    return (
        <div>
            
            <HeroBanner />
            <ImageNav />
            
            <CarouselSection />
            <LeftTextRightPhoto />
          
            <HomeFooter />
      
 
        </div>
    );
};


