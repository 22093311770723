import React from 'react';

import { useState } from 'react';
import { BeakerIcon, CalendarIcon, MapIcon, UserIcon } from '@heroicons/react/24/outline'
import { Helmet } from 'react-helmet';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Carousel from '../Carousel/Carousel';
import Plausible from 'plausible-tracker';
import CountdownTimer from '../CountdownTimer';

//const stripePromise = loadStripe('pk_live_51LEzDhAyT9LpkkZpoVTGqyJrj0PvgfCzHvnbY2s95ssAYETSrtiB1ODubkg1bjlDihOC3SWLoxCOx82dWhsqV2PS00VpOxFpkC');


function SignUp() {    
    const targetDate = "April 30, 2024 23:59:59";
        const {trackEvent} = Plausible();
    
        const handleSubmit = (event) => {
            trackEvent('step2')

            fetch('/api/create-checkout-session', {
                method:'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    items: [
                        {id :1, quantity:1}
                    ]
                })
            }).then(res=>{
                if(res.ok) return res.json()
                return res.json().then(e => Promise.reject(e))
            }).then(({url}) => {
                window.location = url
            }).catch(e => {
                console.error(e.error)
            })

            event.preventDefault(); 
        };

        

    return (
        <div>
            <Helmet>
            <title>MyK9 April $99 Special</title>
            <meta name="description" content="Sign up here for a ticket to our sneak peek event. Come see the luxury space we have created for your furry family member!" />
            <meta property="og:title" content="MyK9 April $99 Special" />
            <meta property="og:description" content="Sign up here for a ticket to our sneak peek event. Come see the luxury space we have created for your furry family member!" />
            <meta property="og:image" content="https://myk9.dog/thumbnail.webp" />
            <meta property="og:url" content="https://myk9.dog/party" />
            </Helmet>

            <div className='md:flex md:justify-center md:mt-20 '>
            <div className='md:flex md:flex-row md:w-4/5 md:px-6 md:border-2 md:rounded-xl md:bg-slate-100 md:shadow-xl'>
            <div className='md:flex md:flex-col md:w-1/2 md:my-8 md:justify-center '>
            <div className="flex w-full justify-center mt-16 md:mt-0">
                <h1 className="text-3xl font-bold text-center px-14">MyK9. Because Your Dog Deserves it.</h1>
            </div>
            <div className="px-8 mt-4">
                <p className="text-center text-xl text-slate-500">We understand that your dog is more than just a pet—they're a member of your family. In our safe, clean, and luxurious facility, your furry family member will recieve the best possible level of care. </p>
            </div>
            <div className="flex justify-center">
            <button onClick={handleSubmit} className="text-white bg-[#57a8cc] border-slate-600 mt-10 mb-2 p-3 px-5 rounded-l font-bold hover:bg-white hover:text-slate-600 plausible-event-name--step2">Get Unlimited Daycare For $99</button>
            </div>
            <div className="flex justify-center flex-col mx-8 mt-8 border-2  border-green-300 rounded-xl bg-green-100 p-4">
                
                <p className="text-center mt-2 font-bold md:text-xl ">⚡️ Limited-time offer! Sale ends in : </p>
                <CountdownTimer targetDate={targetDate} />
                
            </div>
            </div>
            
            <div className="mx-8 md:flex md:flex-col md:w-1/2 md:mx-0 md:h-full md:justify-center">
            
                <Carousel />
            </div>
            </div>
            </div>
        </div>
    );
}

export default SignUp;