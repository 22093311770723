function ImageNav() {
  const handleClick = () => {
    window.open('https://myk9.gingrapp.com/front_end/lead?form_name=lead_form', '_blank', 'noopener,noreferrer');
  };

  const handleClick1 = () => {
    window.open('https://myk9.portal.gingrapp.com/#/public/new_customer', '_blank', 'noopener,noreferrer');
  };

  const handleClick2 = () => {
    window.open('/services');
  }

  return (
    <div className="bg-stone-50 w-full flex justify-center flex-wrap flex-row md:pt-14 md:pb-14 pt-8 pb-12 ">
      {/* Sign up now! Section */}
      <div className="w-full sm:w-auto mx-4 bg-[#f5f5f5] text-[#181028] drop-shadow-lg mt-6 mb-6 flex flex-row">
        <img src="/image/puppydiaganol.webp" className="sm:w-[300px] w-[150px]" />
        <div className="flex max-w-[250px] items-start justify-center h-full flex-col p-2 ml-4">
          <h3 className="sm:text-4xl text-3xl text-[#57a8cc] font-Lobster">Sign up now!</h3>
          <p className="text-slate-600 font-bold">Daycare Done Right</p>
          <p className="text-slate-500 hidden sm:inline">Increased exercise, safe socialization, and exclusive benefits for you and your pup</p>
          <div className="flex justify-center items-center">
            <button onClick={handleClick1} className="bg-[#57a8cc] mt-2 mb-6 p-2 px-3 text-white font-medium" type="button">Get Started</button>
          </div>
        </div>
      </div>

      {/* Get Started and New Parents Sections */}
      <div className="flex mt-6 mb-6 mx-4 gap-6">
        {/* Get Started Section */}
        <div className="drop-shadow-lg relative">
          <div className="absolute right-[8px] bottom-[5px] sm:right-[20px]">
            <p className="text-white font-Lobster text-2xl sm:text-3xl mb-0 flex justify-end">Our Services</p>
            <p className="mb-0 text-right sm:inline text-white ">Treat your pup!</p>
          </div>
          <a href="/services">
          <img className="bg-white hover:opacity-50 hover:cursor-pointer w-[300px]" src="/image/pinkdog.webp" /></a>
        </div>

        {/* New Parents Section */}
        <div className="drop-shadow-lg relative">
          <div className="absolute right-[8px] bottom-[5px] sm:right-[20px]">
            <p className="text-white font-Lobster text-2xl sm:text-3xl flex mb-0 justify-end">New Parents</p>
            <p className="mb-0 text-right sm:inline text-white ">Class Waitlist!</p>
          </div>
          <img onClick={handleClick} className="bg-white hover:opacity-50 hover:cursor-pointer w-[300px]" src="/image/browndog.webp" />
        </div>
      </div>
    </div>
  );
}

export default ImageNav;
