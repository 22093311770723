import React from 'react'
import Carousel from './Carousel/Carousel'

function CarouselSection() {
  return (
    <div className="md:mt-16 mt-10 flex justify-center items-center md:mb-16 w-full">
    <div className=" max-w-[1330px] gap-x-8 flex flex-col md:flex-row  justify-center items-center px-8">
      <div className="h-full justify-center md:w-1/2 md:order-last md-p-x-4">
          <h2 className="md:text-4xl text-2xl text-[#57a8cc] font-Lobster">Pet Care Beyond the Ordinary with Our Boutique Facility</h2>
          <h2 className=" md:block text-xl font-bold text-slate-600 md:text-3xl">Your furry family member deserves more than just doggy daycare.</h2>
          
      </div>
      <div className="-mt-10 md:mt-0 md:w-[690px]">
        
        <Carousel />
      </div>
      
      </div>
    </div>
  )
}

export default CarouselSection
