import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import "./NavBar.css";

function NavBar({ }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(window.innerWidth > 640);
    };

    // Add an event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set the initial state
    handleResize();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="sm:justify-center bg-[#57a8cc] flex items-start justify-start w-full">
    <div className="w-[1330px] flex sm:items-center sm:justify-between sm:flex-row justify-start flex-col p-2 cursor-pointer">
      
      <Link to="/" className="sm:justify-between cursor-pointer no-underline flex flex-row items-center"><img className='max-h-10' src="./logo192.png"></img><p id="title" className="mb-0 text-white">MyK9</p></Link>
      <div className="hidden sm:flex flex-row gap-6">
        <a href="https://www.facebook.com/myk9southwindham" target='_blank'>
        <img className="max-h-6 cursor-pointer hover:scale-110" src="/image/facebook-icon.webp"/></a>
        <a href="https://www.instagram.com/myk9.ct/" target='_blank'>
        <img className="max-h-6 cursor-pointer hover:scale-110" src="/image/instagram-icon.webp"/></a>
        {/* <img className="max-h-6 cursor-pointer hover:scale-110" src="./youtube-icon.webp"/> */}
      </div>
      <div onClick={toggleMenu} className="sm:hidden absolute top-[18px] right-[16px] flex flex-col justify-between w-9 h-8">
      <span className="h-1.5 w-full bg-white rounded"></span>
      <span className="h-1.5 w-full bg-white rounded"></span>
      <span className="h-1.5 w-full bg-white rounded"></span>
      </div>
      {menuOpen && (
      <ul className="sm:flex cursor-pointer pl-0 flex sm:flex-row sm:gap-6 flex-col items-center gap-1 sm:mt-0 no-underline font-black mb-0 text-xl">
        <li className="no-underline sm:px-3 text-white cursor-pointer hover:scale-110 hover:drop-shadow-lg">
          <Link className="no-underline text-white" to="/services">Services</Link>
          </li>
        <li className="no-underline sm:px-3 text-white cursor-pointer hover:scale-110">
          <Link className="no-underline text-white cursor-pointer" to="/contact">Contact</Link>
          </li>
          <a href="https://myk9.portal.gingrapp.com/#/public/login" target='_blank'>
          <li className="no-underline sm:px-3 text-white cursor-pointer hover:scale-110">
          <p className="no-underline text-white cursor-pointer" to="/contact">Login</p>
          </li></a>
      </ul>
      )}
    </div>
    </div>
  );
}

export default NavBar;